





















































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { FETCH_BLOCKED_CAR_LIST, FETCH_COLORS, GET_BLOCKED_BY_YEAR_WEEK, GET_COLORS } from '../store';
import { FETCH_LIST, INIT_CREATE } from '@/modules/appointment/store';
import FullCalendar from '../../../components/FullCalendar.vue';
import CreateAppointmentDialog from '@/modules/appointment/components/CreateAppointmentDialog.vue';
import ViewAppointmentDialog from '@/modules/appointment/components/ViewAppointmentDialog.vue';
import EditAppointmentDialog from '@/modules/appointment/components/EditAppointmentDialog.vue';
import CancelAppointmentDialog from '@/modules/appointment/components/CancelAppointmentDialog.vue';
import { ApiResponse } from '@/components/types';
import { CarColorConfiguration, ReservedSlot } from '../types';
import { AuthorizationMixin } from '@/views/mixin';
import { routeToLocation } from '@/router';

const Car = namespace('car');
const Appointment = namespace('appointment');

@Component({
  components: {
    CancelAppointmentDialog,
    EditAppointmentDialog,
    ViewAppointmentDialog,
    CreateAppointmentDialog,
    FullCalendar
  }
})
export default class Available extends AuthorizationMixin {
  @Car.Action(FETCH_BLOCKED_CAR_LIST) fetchBlocks!: (yearWeek: string) => Promise<ApiResponse<ReservedSlot[]>>;
  @Car.Getter(GET_BLOCKED_BY_YEAR_WEEK) listByWeekYear!: (yearWeek: string) => ReservedSlot[];

  @Car.Action(FETCH_COLORS) fetchColors!: () => Promise<ApiResponse<CarColorConfiguration[]>>;
  @Car.Getter(GET_COLORS) colors!: CarColorConfiguration[];

  @Appointment.Mutation(INIT_CREATE) initCreate!: (data: any) => void;
  @Appointment.Action(FETCH_LIST) fetchAppointment!: () => Promise<ApiResponse>;

  yearWeek: string = format(Date.now(), 'yyyyii');

  event: any = null;
  details: boolean = false;

  get viewAppointment(): string {
    return this.$route.query['show'] as string;
  }

  set viewAppointment(show: string) {
    if (!show) {
      const { show, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, show } })).catch(() => {});
  }

  get editAppointment(): string {
    return this.$route.query['edit'] as string;
  }

  set editAppointment(edit: string) {
    if (!edit) {
      const { edit, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, edit } })).catch(() => {});
  }

  get cancelAppointment(): string {
    return this.$route.query['cancel'] as string;
  }

  set cancelAppointment(cancel: string) {
    if (!cancel) {
      const { cancel, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, cancel } })).catch(() => {});
  }

  get events(): object[] {
    return this.listByWeekYear(this.yearWeek).map(({ appointmentId, employee, ...entry }) => ({
      title: `${entry.name}\n${employee.name}`,
      start: entry.from,
      end: entry.to,
      backgroundColor: this.color(entry),
      borderColor: '#ffffff',
      appointmentId,
      employeeId: employee.id
    }));
  }

  get defaultView(): string {
    return this.$vuetify.breakpoint.mdAndUp ? 'agendaWeek' : 'agendaDay';
  }

  onClick(event: any): void {
    if (this.isAdmin() || event.employeeId === this.$auth.user().id) {
      this.viewAppointment = event.appointmentId;
      return;
    }

    this.event = event;
    this.details = true;
  }

  onDayClick(event: any): void {
    if (!event) return;

    const begin = event.format('YYYY-MM-DD HH:mm:ss');
    const end = event.add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss');

    setTimeout(() => {
      this.initCreate({ employee: this.$auth.user().id, start: begin, begin, end });
    }, 200);
  }

  select({ start, end }: any): void {
    const begin = start.format('YYYY-MM-DD HH:mm:ss');
    const ends = end.format('YYYY-MM-DD HH:mm:ss');

    setTimeout(() => {
      this.initCreate({ employee: this.$auth.user().id, start: begin, begin, end: ends });
    }, 200);
  }

  color(event: any): string {
    if (event.employee === this.$auth.user().fullName) {
      return 'green darken-3';
    }

    const config = this.colors.find(config => config.id === event.id);

    if (config) {
      return config.color;
    }

    return '#3a87ad';
  }

  fetchEvents(weekDay: string): Promise<ApiResponse> {
    this.yearWeek = format(weekDay, 'yyyyww');

    return this.fetchBlocks(weekDay);
  }

  async created(): Promise<ApiResponse> {
    await this.fetchAppointment();
    await this.fetchColors();

    return this.fetchEvents(format(Date.now(), 'yyyy-MM-dd'));
  }
}
